.leaflet-container {
  height: 1400px;
  width: 100vw;
}

@media (min-width: 1000px) {
  .leaflet-container {
    height: 100vh;
  }
}

.leaflet-div-icon {
  background: transparent;
  border: none;
}

.leaflet-layer,
.leaflet-control-zoom-in,
.leaflet-control-zoom-out,
.leaflet-control-attribution {
  filter: invert(100%) hue-rotate(180deg) brightness(95%) contrast(90%);
}
