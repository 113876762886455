.swiper-button-prev {
	background: white;
	border-radius: 50%;
	width: 40px;
	height: 40px;
}

.swiper-button-next {
	background: white;
	border-radius: 50%;
	width: 40px;
	height: 40px;
}
.container {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	width: 100vw;
}

.swiper-container {
	width: 100vw;
	height: 100%;
}
